import { ChevronRightIcon } from '@heroicons/react/outline';
import { CheckIcon } from '@heroicons/react/solid';
import Button from '@/components/elements/button';
import ContentHeadline from '../content/headline';
import UpsFeature from './ups/feature';
import UpsFeatureRight from './ups/feature-right';

import AdacIcon from '../../../public/images/adac.svg';
import SicherIcon from '../../../public/assets/icons/sicher.svg';
import BeraterIcon from '../../../public/assets/icons/Berater.svg';
import DiffIcon from '../../../public/assets/icons/Vergleichangebot.svg';
import BookingOptions from './booking-options';

export default function TopFeatures() {
  return (
    <div className="max-w-7xl mx-auto w-full px-6 xl:px-0 pt-8 pb-4">
      <div className="flex flex-col md:grid md:grid-cols-5 mb-10">
        <div className="md:col-span-5 order-2 md:order-1 px-3 md:px-0">

          <ContentHeadline tag="1" className="mb-2" after="Yachtcharter auf allen Weltmeeren">
            Chartercheck&nbsp;-
            {' '}
          </ContentHeadline>

          <div className="grid md:grid-cols-2 gap-10">
            <div>
              <p className="text-lg text-primary">
                <b>Chartercheck</b>
                {' '}
                ist eines der führenden Buchungsportale für Segel- und Motoryachtcharter weltweit.
                Direkte Verfügbarkeit von
                {' '}
                <b>über 20.000 Segelyachten, Motoryachten sowie Segel- und Motorkatamarane</b>
                {' '}
                in allen europäischen Hotspots wie z.B. Kroatien, Griechenland, Italien oder Spanien. Weltenbummler
                buchen neben der Karibik viele interessante Destinationen auf allen sieben Weltmeeren bei CharterCheck
                mit Livedaten.

                Über unser Portal
                {' '}
                <a
                  href="https://www.bootsreisen24.de"
                  className="font-bold underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  Bootsreisen24.de
                </a>
                {' '}
                finden Sie übrigens weitere 3.000 Hausboote, Yachten und Floating-Houses in Europa und Canada.

                Als ADAC Mitglied erhalten Sie außerdem auf alle Angebote einen Mitgliedsrabatt, der Ihnen bei der Suche
                angezeigt und bei der Buchung angerechnet wird.
              </p>
              <div className="mt-5">
                <Button href="/ueber-uns" size="sm" width="w-64">Warum CharterCheck?</Button>
              </div>
            </div>

            <div>
              <ul className="space-y-1 text-lg text-primary font-bold ">
                <li className="flex items-center">
                  <span className="bg-primary inline-block mr-4 p-1 rounded-full text-action">
                    <CheckIcon className="h-4" />
                  </span>
                  <span>Kooperationspartner des ADAC (Mitgliederrabatt)</span>
                </li>
                <li className="flex items-center">
                  <span className="bg-primary inline-block mr-4 p-1 rounded-full text-action">
                    <CheckIcon className="h-4" />
                  </span>
                  Verfügbarkeiten direkt ansehen und Online buchen
                </li>

                <li className="flex items-center">
                  <span className="bg-primary inline-block mr-4 p-1 rounded-full text-action">
                    <CheckIcon className="h-4" />
                  </span>
                  Kostenlose Reservierung für Ihre Wunschyacht
                </li>

                <li className="flex items-center">
                  <span className="bg-primary inline-block mr-4 p-1 rounded-full text-action">
                    <CheckIcon className="h-4" />
                  </span>
                  Preisgarantie
                </li>
              </ul>

            </div>
          </div>
        </div>
      </div>

      <BookingOptions />

      <div className="hidden">
        <ContentHeadline tag="h2" after="Vorteile" className="mb-2">Unsere</ContentHeadline>
        <div
          className="mt-8 grid grid-flow-row grid-cols-2 gap-2 text-primary-500 md:grid-cols-5"
        >
          <UpsFeature full description="lorem ipsum">
            <AdacIcon
              className="w-full -mb-3"
            />
          </UpsFeature>
          <UpsFeature title="Über 500 Berater">
            <BeraterIcon
              className="w-full mx-2 md:mx-5 fill-action"
            />
          </UpsFeature>
          <UpsFeature title="Buchen ohne Risiko">
            <SicherIcon
              className="w-full mx-2 md:mx-5 fill-action"
            />
          </UpsFeature>
          <UpsFeature title="Vergleichs&shy;angebot">
            <DiffIcon
              className="w-full mx-2 md:mx-5 fill-action"
            />
          </UpsFeature>
          <UpsFeatureRight
            title="Warum CharterCheck?"
            description="Maecenas faucibus mollis interdum."
            className="col-span-2 md:col-span-1"
          >
            <ChevronRightIcon className="w-14 mt-5" />
          </UpsFeatureRight>
        </div>
      </div>
    </div>
  );
}
