import EarlyBookingDiscount from '@/components/home/early-booking-discount';

export default function IndexHero({ withDiscount }) {
  return (
    <div
      id="header-image"
      className={`h-[300px] lg:h-[500px] mb-[280px] md:mb-[100px] lg:mb-[50px] flex justify-center ${withDiscount && 'shadow-box'}`}
    >
      {withDiscount && (<EarlyBookingDiscount />)}
    </div>
  );
}
